import React, {
    useState,
    useEffect,
    useRef,
    useCallback,
    useMemo
} from 'react';
import * as Slider from '@radix-ui/react-slider';
import * as Select from '@radix-ui/react-select';
import { styled } from '@stitches/react';
import * as Switch from '@radix-ui/react-switch';
import { ChevronDownIcon, CheckIcon } from '@radix-ui/react-icons';
import './App.css';

// Import all styled components
import {
    Container,
    Title,
    SliderRoot,
    SliderTrack,
    SliderRange,
    SliderThumb,
    SelectTrigger,
    SelectIcon,
    SelectContent,
    SelectViewport,
    SelectItem,
    SelectItemIndicator,
    SelectLabel,
    SelectSeparator,
    SelectScrollUpButton,
    SelectScrollDownButton,
    TableContainer,
    HoverIndicator,
    ToggleContainer,
    ToggleLabel,
    StyledSwitch,
    StyledThumb,
    Table,
    TableRow,
    TableCell,
    HourCell,
    Hour,
    AmPm,
    RemoveButton,
    DateCell,
    EventCell,
    InputContainer,
    Input,
    PeopleList,
    PersonTag,
    PersonCheckbox,
    FocusInputContainer,
    FocusInput,
    FocusButton,
    AmPmToggle,
    AmPmButton,
    ProfilePic,
    PersonName,
    ProfileRow,
    ProfileCell,
    TZCell,
    TZName,
    TZTime,
    TimeDisplay,
    BlinkingColon,
    CalRow,
    CalCell,
    EventCellEmpty,
    EventContainer,
    EventItem,
    EventCellCal,
    AnimatedHoverIndicator,
    AnimatedTable,
    TestButton,
    AnimatedTableCell,
    AnimatedTableRow,
    AnimatedProfileRow
} from './styles';

const availableTimezones = [
    { name: 'Tokyo 🇯🇵', timezone: 'Asia/Tokyo' },
    { name: 'Taipei 🇹🇼', timezone: 'Asia/Taipei' },
    { name: 'San Francisco 🌉', timezone: 'America/Los_Angeles' },
    { name: 'New York 🗽', timezone: 'America/New_York' },
    { name: 'London 🎠', timezone: 'Europe/London' },
    { name: 'Chicago 🍕', timezone: 'America/Chicago' },
    { name: 'Toronto 🇨🇦', timezone: 'America/Toronto' },
    { name: 'Michigan 👨🏼‍🦯', timezone: 'US/Michigan' },
    { name: 'Denver 🌿', timezone: 'America/Denver' },
    { name: 'Oklahoma 🌵', timezone: 'America/Chicago' },
    { name: 'Jerusalem 🍎', timezone: 'Asia/Jerusalem' },
    { name: 'Florida 🐊', timezone: 'America/New_York' },
    { name: 'Manila 🇵🇭', timezone: 'Asia/Manila' }
];

const App = () => {
    const [people, setPeople] = useState([
        {
            id: 1,
            name: 'Oliy',
            timezone: 'America/Los_Angeles',
            isSelected: false,
            profilePic: `/pfp/oliy.png`
        },
        {
            id: 14,
            name: 'Jasmine',
            timezone: 'America/Los_Angeles', //Asia/Taipei
            isSelected: false,
            profilePic: `/pfp/jasmine.jpg`
        },
        {
            id: 15,
            name: 'Sharlene',
            timezone: 'Asia/Manila',
            isSelected: false,
            profilePic: `/pfp/sharlene.jpg`
        },
        {
            id: 9,
            name: 'Roee',
            timezone: 'Asia/Jerusalem',
            isSelected: false,
            profilePic: `/pfp/roee.jpg`
        },
        // {
        //     id: 10,
        //     name: 'Qais',
        //     timezone: 'Asia/Jerusalem',
        //     isSelected: false,
        //     profilePic: `/pfp/qais.jpg`
        // },
        // {
        //     id: 17,
        //     name: 'Isabel',
        //     timezone: 'Europe/London',
        //     isSelected: false,
        //     profilePic: `/pfp/iz.webp`
        // },
        {
            id: 18,
            name: 'Carolyn',
            timezone: 'America/Toronto',
            isSelected: false,
            profilePic: `/pfp/carolyn.jpg`
        },
        {
            id: 3,
            name: 'Christen',
            timezone: 'US/Michigan',
            isSelected: false,
            profilePic: `/pfp/christen.jpg`
        },
        {
            id: 8,
            name: 'Matthew',
            timezone: 'America/New_York',
            isSelected: false,
            profilePic: `/pfp/matthew.png`
        },
        {
            id: 12,
            name: 'Andy',
            timezone: 'America/New_York',
            isSelected: false,
            profilePic: `/pfp/andy.jpg`
        },
        // {
        //     id: 4,
        //     name: 'Cassie',
        //     timezone: 'America/Chicago',
        //     isSelected: false,
        //     profilePic: `/pfp/cassie.jpg`
        // },
        // {
        //     id: 5,
        //     name: 'Tika',
        //     timezone: 'America/Chicago',
        //     isSelected: false,
        //     profilePic: `/pfp/tika.jpg`
        // },
        {
            id: 7,
            name: 'Drew',
            timezone: 'America/Chicago',
            isSelected: false,
            profilePic: `/pfp/drew.jpg`
        },
        // {
        //     id: 6,
        //     name: 'Ken',
        //     timezone: 'America/Denver',
        //     isSelected: false,
        //     profilePic: `/pfp/ken.jpg`
        // },
        {
            id: 2,
            name: 'Yoav',
            timezone: 'America/Los_Angeles',
            isSelected: false,
            profilePic: `/pfp/yoav.jpg`
        },
        // {
        //     id: 11,
        //     name: 'Deryck',
        //     timezone: 'America/Los_Angeles',
        //     isSelected: false,
        //     profilePic: `/pfp/deryck.jpg`
        // }
    ]);

    const [hasSelection, setHasSelection] = useState(false);

    const renderProfilePictures = (timezone) => {
        return people
            .filter((person) => person.timezone === timezone)
            .map((person) => (
                <ProfilePic
                    key={person.id}
                    src={person.profilePic}
                    alt={person.name}
                    draggable={false}
                    onDragStart={(e) => e.preventDefault()}
                    style={{
                        opacity: hasSelection
                            ? person.isSelected
                                ? 1
                                : 0.3
                            : 1,
                        pointerEvents: 'none'
                    }}
                />
            ));
    };

    interface SelectComponentProps {
        value: string;
        onValueChange: (value: string) => void;
        onOpenChange?: (open: boolean) => void;
        items: { value: string; label: string }[];
        placeholder: string;
    }

    const SelectComponent = React.forwardRef<
        HTMLButtonElement,
        SelectComponentProps
    >(({ value, onValueChange, items, placeholder }, ref) => (
        <Select.Root value={value} onValueChange={onValueChange}>
            <SelectTrigger
                ref={ref as React.Ref<HTMLButtonElement>}
                aria-label={placeholder}>
                <Select.Value placeholder={placeholder} />
                <SelectIcon>
                    <ChevronDownIcon />
                </SelectIcon>
            </SelectTrigger>
            <Select.Portal>
                <SelectContent>
                    <SelectScrollUpButton />
                    <SelectViewport>
                        {items.map((item, i) => (
                            <SelectItem
                                key={`${item.value}-${i}`}
                                value={item.value}>
                                <Select.ItemText>{item.label}</Select.ItemText>
                                <SelectItemIndicator>
                                    <CheckIcon />
                                </SelectItemIndicator>
                            </SelectItem>
                        ))}
                    </SelectViewport>
                    <SelectScrollDownButton />
                </SelectContent>
            </Select.Portal>
        </Select.Root>
    ));

    const [newPersonName, setNewPersonName] = useState('');
    const [newPersonTimezone, setNewPersonTimezone] = useState('');

    const [baseTime, setBaseTime] = useState(new Date());
    const [timeOffset, setTimeOffset] = useState(0);
    const [isSliding, setIsSliding] = useState(false);

    const [hoveredColumn, setHoveredColumn] = useState(null);
    const [selectedTimezones, setSelectedTimezones] = useState([
        { name: 'Taipei', timezone: 'Asia/Taipei' },
        { name: 'PDT', timezone: 'America/Los_Angeles' },
        { name: 'Tokyo', timezone: 'Asia/Tokyo' }
    ]);
    const [dateInput, setDateInput] = useState(
        new Date().toISOString().split('T')[0]
    );
    const [timeInput, setTimeInput] = useState(
        new Date().toTimeString().slice(0, 5)
    );
    const [selectedTimezone, setSelectedTimezone] = useState('America/Los_Angeles'); // need to make this selectable //Asia/Taipei

    useEffect(() => {
        const updateBaseTime = () => {
            let newDate = new Date(`${dateInput}T${timeInput}`);

            if (selectedTimezone !== 'local') {
                // Convert the input time to UTC
                const utcDate = new Date(
                    newDate.toLocaleString('en-US', {
                        timeZone: selectedTimezone
                    })
                );
                const offset = utcDate.getTime() - newDate.getTime();
                newDate = new Date(newDate.getTime() + offset);
            }

            setBaseTime(newDate);
        };

        updateBaseTime();
    }, [dateInput, timeInput, selectedTimezone]);

    useEffect(() => {
        const timer = setInterval(() => {
            setBaseTime(new Date());
        }, 60000); // Update every minute

        return () => clearInterval(timer);
    }, []);

    const [calendarEvents, setCalendarEvents] = useState([]);

    useEffect(() => {
        const fetchCalendarEvents = async () => {
            try {
                const response = await fetch(
                    'http://localhost:42556/calendar-events'
                );
                const data = await response.json();
                setCalendarEvents(data);
            } catch (error) {
                console.error('Error fetching calendar events:', error);
            }
        };

        fetchCalendarEvents();

        const interval = setInterval(fetchCalendarEvents, 60000); // 300,000 ms = 5 minutes

        return () => clearInterval(interval); // Cleanup interval on component unmount
    }, []);

    const formatTime = (date) =>
        date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

    const formatDate = (date) =>
        date.toLocaleDateString([], { month: 'short', day: 'numeric' });

const isCurrentHour = (hour, locationTime) => {
    const currentHour = locationTime.getHours();
    return currentHour === hour
};

const handleAddTimezone = (timezone) => {
    if (
        !selectedTimezones.some((tz) => tz.timezone === timezone.timezone)
    ) {
        setSelectedTimezones([...selectedTimezones, timezone]);
    }
};

const handleRemoveTimezone = (timezone) => {
    setSelectedTimezones(
        selectedTimezones.filter((tz) => tz.timezone !== timezone.timezone)
    );
};

const calculateDuration = (start, end) => {
    const diff = end.getTime() - start.getTime();
    const minutes = Math.round(diff / 60000);
    if (minutes < 60) {
        return `${minutes}m`;
    } else {
        const hours = Math.floor(minutes / 60);
        const remainingMinutes = minutes % 60;
        return remainingMinutes > 0
            ? `${hours}h ${remainingMinutes}m`
            : `${hours}h`;
    }
};

// const [hoverPosition, setHoverPosition] = useState(null);
const [focusedColumn, setFocusedColumn] = useState(null);
// const tableRef = useRef(null);
// const handleCellHover = (event, rowIndex, cellIndex) => {
//     if (!tableRef.current) return;

//     const table = tableRef.current;
//     const cell = event.currentTarget;
//     const tableRect = table.getBoundingClientRect();
//     const cellRect = cell.getBoundingClientRect();

//     setHoverPosition({
//         left: cellRect.left - tableRect.left,
//         width: cellRect.width,
//         height: table.clientHeight
//     });
//     setHoveredColumn(cellIndex);
// };

// set hover effect to current hour when there is no hover
const [currentHourColumn, setCurrentHourColumn] = useState(null);

const calculateCurrentHourColumn = useCallback(() => {
    const now = new Date();
    return ((now.getHours() - baseTime.getHours() + 27) % 24) + 2;
}, [baseTime]);

const handleCellClick = (columnIndex) => {
    setFocusedColumn(columnIndex);
};

const handleDefocus = useCallback(() => {
    setFocusedColumn(null);
}, []);

const [hoverPosition, setHoverPosition] = useState(null);
const tableRef = useRef(null);

const calculateColumnPosition = useCallback((columnIndex) => {
    if (!tableRef.current) return null;

    const table = tableRef.current;
    const tableRect = table.getBoundingClientRect();
    // const firstColumnWidth = table.rows[0].cells[0].offsetWidth;
    const firstColumnWidth = 75 + 37.5;

    const remainingWidth = tableRect.width - firstColumnWidth;
    // const regularColumnWidth = remainingWidth / 27; // 26 regular columns
    const regularColumnWidth = 65; // 26 regular columns

    let left, width;

    if (columnIndex === 0) {
        left = 0;
        width = firstColumnWidth;
    } else {
        left = firstColumnWidth + (columnIndex - 1) * regularColumnWidth;
        width = regularColumnWidth;
    }

    // console.log(
    //     'left:',
    //     left,
    //     'width:',
    //     width,
    //     'height:',
    //     tableRect.height
    // );
    return {
        left,
        width,
        height: tableRect.height
    };
}, []);

useEffect(() => {
    const updateCurrentHourColumn = () => {
        setCurrentHourColumn(calculateCurrentHourColumn());
    };

    updateCurrentHourColumn(); // Initial update
    const intervalId = setInterval(updateCurrentHourColumn, 60000); // Update every minute

    return () => clearInterval(intervalId);
}, [calculateCurrentHourColumn]);

const handleCellHover = (event, rowIndex, cellIndex) => {
    const position = calculateColumnPosition(cellIndex);
    if (position) {
        setHoverPosition(position);
        setHoveredColumn(cellIndex);
    }
};

const handleTableMouseLeave = useCallback(() => {
    if (focusedColumn !== null) {
        const position = calculateColumnPosition(focusedColumn);
        if (position) {
            setHoverPosition(position);
            setHoveredColumn(focusedColumn);
        }
    } else {
        const position = calculateColumnPosition(currentHourColumn - 1);
        if (position) {
            setHoverPosition(position);
            setHoveredColumn(currentHourColumn);
        }
    }
}, [focusedColumn, currentHourColumn, calculateColumnPosition]);

useEffect(() => {
    const handleKeyDown = (event) => {
        if (event.key === 'Escape') {
            handleDefocus();
        }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
        document.removeEventListener('keydown', handleKeyDown);
    };
}, [handleDefocus]);

useEffect(() => {
    const handleClickOutside = (event) => {
        if (tableRef.current && !tableRef.current.contains(event.target)) {
            handleDefocus();
        }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
        document.removeEventListener('mousedown', handleClickOutside);
    };
}, [handleDefocus]);

const handleSliderChange = ([value]) => {
    const hours = Math.floor(value / 60);
    const minutes = value % 60;
    
    // Update time immediately without animation
    const newTime = new Date();
    newTime.setHours(hours, minutes);
    setBaseTime(newTime);
    setCurrentHourColumn(calculateCurrentHourColumn());
};

const handleAddPerson = () => {
    if (newPersonName && newPersonTimezone) {
        setPeople([
            ...people,
            {
                id: people.length + 1,
                name: newPersonName,
                timezone: newPersonTimezone,
                isSelected: true,
                profilePic: `https://placewaifu.com/image/50`
            }
        ]);
        setNewPersonName('');
        setNewPersonTimezone('');
    }
};

// const handleTogglePerson = (id) => {
//     setPeople(
//         people.map((person) =>
//             person.id === id
//                 ? { ...person, isSelected: !person.isSelected }
//                 : person
//         )
//     );
// };

// const handleTogglePerson = (id) => {
//     setPeople((prevPeople) => {
//         const updatedPeople = prevPeople.map((person) =>
//             person.id === id
//                 ? { ...person, isSelected: !person.isSelected }
//                 : person
//         );
//         const anySelected = updatedPeople.some(
//             (person) => person.isSelected
//         );
//         setHasSelection(anySelected);
//         return updatedPeople;
//     });
// };

const getTimezoneOffset = useCallback((timezone: string) => {
    const now = new Date();
    const tzTime = new Date(now.toLocaleString('en-US', { timeZone: timezone }));
    const localTime = new Date(now.toLocaleString('en-US', { timeZone: 'UTC' }));
    return (tzTime.getTime() - localTime.getTime()) / (1000 * 60 * 60);
}, []);

const visibleTimezones = React.useMemo(() => {
    const selectedPeople = people.filter((person) =>
        hasSelection ? person.isSelected : true
    );
    const uniqueTimezones = Array.from(
        new Set(selectedPeople.map((person) => person.timezone))
    );

    // Sort timezones by UTC offset
    return uniqueTimezones.sort((a, b) => {
        const offsetA = getTimezoneOffset(a);
        const offsetB = getTimezoneOffset(b);
        return offsetB - offsetA; // Descending order (UTC+24 to UTC-24)
    });
}, [people, hasSelection, getTimezoneOffset]);

const [is24HourFormat, setIs24HourFormat] = useState(false);

const formatHour = (hour) => {
    if (is24HourFormat) {
        return hour.toString().padStart(2, '0');
    } else {
        const period = hour >= 12 ? 'PM' : 'AM';
        const displayHour = hour % 12 || 12;
        return (
            <HourCell>
                <Hour>{displayHour}</Hour>
                <AmPm>{period}</AmPm>
            </HourCell>
        );
    }
};

const [focusHourInput, setFocusHourInput] = useState('');
const [focusTimezone, setFocusTimezone] = useState('');
const [focusPeriod, setFocusPeriod] = useState('AM');

const hourInputRef = useRef(null);
const amButtonRef = useRef(null);
const pmButtonRef = useRef(null);
const timezoneSelectRef = useRef(null);
const focusButtonRef = useRef(null);

const [currentTime, setCurrentTime] = useState(new Date());

const getLocationTime = useCallback(
    (timezone, offset) => {
        const localTime = baseTime.toLocaleString('en-US', {
            timeZone: timezone
        });
        const locationTime = new Date(localTime);
        locationTime.setHours(locationTime.getHours() + offset);
        return locationTime;
    },
    [currentTime]
);


const getLocationTimeLive = useCallback(
(timezone) => {
    return new Date(
        baseTime.toLocaleString('en-US', { timeZone: timezone })
    );
},
[baseTime] // Depend on baseTime instead of currentTime
);

const handleFocusHour = useCallback(() => {
if (focusHourInput && focusTimezone) {
    let hour = parseInt(focusHourInput, 10);
    if (!is24HourFormat) {
        if (hour === 12) {
            hour = focusPeriod === 'AM' ? 0 : 12;
        } else if (focusPeriod === 'PM') {
            hour += 12;
        }
    }
    if (hour >= 0 && hour < 24) {
        const baseTime = getLocationTime(focusTimezone, 0);
        const columnIndex =
            ((hour - baseTime.getHours() + 27) % 24) + 1;
        setFocusedColumn(columnIndex);
    }
}
}, [
focusHourInput,
focusTimezone,
focusPeriod,
is24HourFormat,
getLocationTime
]);

const handleKeyPress = (event) => {
if (event.key === 'Enter') {
    handleFocusHour();
}
};

const handleFocusHourChange = (e) => {
const value = e.target.value;
if (
    value === '' ||
    (parseInt(value, 10) >= 0 &&
        parseInt(value, 10) <= (is24HourFormat ? 23 : 12))
) {
    setFocusHourInput(value);
}
};

useEffect(() => {
handleFocusHour();
}, [focusTimezone, handleFocusHour]);

const [isSelectOpen, setIsSelectOpen] = useState(false);

const handleKeyDown = useCallback(
(event) => {
    const activeElement = document.activeElement;

    if (event.key === 'ArrowLeft') {
        if (activeElement === pmButtonRef.current) {
            amButtonRef.current.focus();
        } else if (activeElement === timezoneSelectRef.current) {
            is24HourFormat
                ? hourInputRef.current.focus()
                : pmButtonRef.current.focus();
        } else if (activeElement === focusButtonRef.current) {
            timezoneSelectRef.current.focus();
        }
    } else if (event.key === 'ArrowRight') {
        if (activeElement === hourInputRef.current) {
            is24HourFormat
                ? timezoneSelectRef.current.focus()
                : amButtonRef.current.focus();
        } else if (activeElement === amButtonRef.current) {
            pmButtonRef.current.focus();
        } else if (activeElement === pmButtonRef.current) {
            timezoneSelectRef.current.focus();
        } else if (activeElement === timezoneSelectRef.current) {
            focusButtonRef.current.focus();
        }
    }
},
[isSelectOpen]
);

const handleSelectOpenChange = (open) => {
setIsSelectOpen(open);
};

const [currentHour, setCurrentHour] = useState(new Date().getHours());

useEffect(() => {
const updateTime = () => {
    const now = new Date();
    setCurrentTime(now);
    setCurrentHour(now.getHours());
    setCurrentHourColumn(calculateCurrentHourColumn());
};

updateTime(); // Initial update
const intervalId = setInterval(updateTime, 60000); // Update every minute

return () => clearInterval(intervalId);
}, [calculateCurrentHourColumn]);

const [showColon, setShowColon] = useState(true);

useEffect(() => {
const timer = setInterval(() => {
    setCurrentTime(new Date());
}, 10000); // Update every minute

return () => clearInterval(timer);
}, []);

useEffect(() => {
const blinkTimer = setInterval(() => {
    setShowColon((prev) => !prev);
}, 900); // Blink every half second

return () => clearInterval(blinkTimer);
}, []);

const formatTimeWithBlink = (date) => {
const hours = date.getHours().toString().padStart(2, '0');
const minutes = date.getMinutes().toString().padStart(2, '0');
return (
    <TimeDisplay>
        {hours}
        <BlinkingColon visible={showColon}>:</BlinkingColon>
        {minutes}
    </TimeDisplay>
);
};

const getDay = (date) => {
return date.toLocaleString('en-US', { weekday: 'short' });
};

const getMajorityDay = useCallback(
(timezones) => {
    const days = timezones.map((tz) => getDay(getLocationTimeLive(tz)));
    const dayCounts = days.reduce((acc, day) => {
        acc[day] = (acc[day] || 0) + 1;
        return acc;
    }, {});
    return Object.entries(dayCounts).reduce((a, b) =>
        a[1] > b[1] ? a : b
    )[0];
},
[getLocationTime]
);

const majorityDay = useMemo(
() => getMajorityDay(visibleTimezones),
[getMajorityDay, visibleTimezones]
);

const renderCalendarEvents = useCallback(() => {
const cells = Array(27)
    .fill(null)
    .map(() => []);
const currentHour = baseTime.getHours();

calendarEvents.forEach((event) => {
    if (!event.start || !event.end) return;
    const startDateTime = new Date(event.start);
    const endDateTime = new Date(event.end);
    const startHour = startDateTime.getHours();
    const startMinutes = startDateTime.getMinutes();
    const endHour = endDateTime.getHours();
    const endMinutes = endDateTime.getMinutes();

    const startIndex = (startHour - currentHour + 3 + 24) % 24;
    let endIndex = (endHour - currentHour + 3 + 24) % 24;

    if (endMinutes === 0) {
        endIndex = (endIndex - 1 + 24) % 24;
    }

    const duration = calculateDuration(startDateTime, endDateTime);

    for (let i = startIndex; i <= endIndex; i++) {
        const index = i % 27;
        if (index >= 0 && index < 27) {
            cells[index].push({
                id: event.id,
                title: event.title,
                isStart: i === startIndex,
                startTime: startDateTime,
                duration: duration
            });
        }
    }
});

return cells.map((cellEvents, index) => (
    <EventCellCal key={`event-cell-${index}`}>
        <EventContainer>
            {cellEvents.map((event, eventIndex) => (
                <EventItem
                    key={`event-${event.id}-${eventIndex}`}
                    style={{
                        backgroundColor: `hsl(${
                            (event.id * 100) % 360
                        }, 75%, 45%)`
                    }}
                    greyed={
                        focusedColumn !== null &&
                        focusedColumn !== index + 1
                    }>
                    {event.isStart ? (
                        <>
                            {event.startTime.toLocaleTimeString([], {
                                hour: '2-digit',
                                minute: '2-digit',
                                hour12: false
                            })}{' '}
                            <br />
                            {event.title} {event.duration}
                        </>
                    ) : (
                        event.title
                    )}
                </EventItem>
            ))}
        </EventContainer>
    </EventCellCal>
));
}, [baseTime, calendarEvents, focusedColumn]);

const [cellAnimation, setCellAnimation] = useState('');
const [tableAnimation, setTableAnimation] = useState('');
const [rowAnimations, setRowAnimations] = useState({});
const [hoverIndicatorTransform, setHoverIndicatorTransform] =
useState('translateX(0px)');
const cellWidth = 65; // Adjust this value based on your cell width

const handleHourChange = useCallback(() => {
setCellAnimation('slide-left');
setTableAnimation('slide-left');
setHoverIndicatorTransform(`translateX(-${cellWidth}px)`);

setTimeout(() => {
    setTableAnimation('slide-right');
    setCellAnimation('slide-right');
    setHoverIndicatorTransform('translateX(0px)');
    setCurrentHourColumn(calculateCurrentHourColumn());
}, 500);

setTimeout(() => {
    setTableAnimation('');
    setCellAnimation('');
}, 1000);
}, [calculateCurrentHourColumn]);

useEffect(() => {
const updateTime = () => {
    const now = new Date();
    setCurrentTime(now);
    const newHour = now.getHours();

    if (newHour !== currentHour) {
        setCurrentHour(newHour);
        handleHourChange();
    }
};

updateTime();
const intervalId = setInterval(updateTime, 1000); // Check every second

return () => clearInterval(intervalId);
}, [currentHour, handleHourChange]);

// Function to test the animation
const testAnimation = () => {
handleHourChange();
};

const handleTogglePerson = useCallback((id) => {
setPeople((prevPeople) => {
    const updatedPeople = prevPeople.map((person) =>
        person.id === id
            ? { ...person, isSelected: !person.isSelected }
            : person
    );
    const anySelected = updatedPeople.some(
        (person) => person.isSelected
    );
    setHasSelection(anySelected);

    // Animate rows
    const newRowAnimations = {};
    updatedPeople.forEach((person) => {
        if (person.id === id) {
            newRowAnimations[person.timezone] = person.isSelected
                ? 'fade-in'
                : 'fade-out';
        }
    });
    setRowAnimations(newRowAnimations);

    // Clear animations after they complete
    setTimeout(() => {
        setRowAnimations({});
    }, 500);

    return updatedPeople;
});
}, []);

const renderAnimatedCell = (hour, isHighlighted, isFocused) => {
return (
    <AnimatedTableCell
        className={cellAnimation}
        style={{
            backgroundColor: isHighlighted
                ? 'rgba(76, 154, 255, 0.2)'
                : isFocused
                ? 'rgba(76, 154, 255, 0.2)'
                : 'transparent',
            color: isHighlighted || isFocused ? '#ffffff' : 'inherit',
            opacity: focusedColumn !== null && !isFocused ? 0.3 : 1,
            cursor: 'pointer'
        }}>
        {formatHour(hour)}
    </AnimatedTableCell>
);
};

const getFormattedDate = useCallback((locationTime, cellIndex) => {
const date = new Date(locationTime);
date.setHours(date.getHours() + cellIndex - 3);
return date.toLocaleDateString([], { month: 'short', day: 'numeric' });
}, []);

return (
<Container>
    {/* <TestButton onClick={testAnimation}>
        Test Hour Change Animation
    </TestButton> */}
    {/* People management */}
    {/* <InputContainer>
        <Input
            type="text"
            placeholder="Name"
            value={newPersonName}
            onChange={(e) => setNewPersonName(e.target.value)}
        />
        <SelectComponent
            value={newPersonTimezone}
            onValueChange={setNewPersonTimezone}
            items={availableTimezones.map((tz) => ({
                value: tz.timezone,
                label: tz.name
            }))}
            placeholder="Select Timezone"
        />
        <button onClick={handleAddPerson}>Add Person</button>
    </InputContainer> */}

    {/* People list */}
    <PeopleList>
        <Title>time.fish</Title>
        {people.map((person) => (
            <PersonTag key={person.id}>
                {/* <PersonCheckbox
                    type="checkbox"
                    checked={person.isSelected}
                    onChange={() => handleTogglePerson(person.id)}
                /> */}
                <ProfilePic
                    src={person.profilePic}
                    alt={person.name}
                    draggable={false}
                    onDragStart={(e) => e.preventDefault()}
                    style={{
                        transition: 'opacity 0.2s',
                        opacity: hasSelection
                            ? person.isSelected
                                ? 1
                                : 0.3
                            : 1
                    }}
                    onClick={() => handleTogglePerson(person.id)}
                />
                {/* <PersonName>{person.name}</PersonName>(
                {
                    availableTimezones.find(
                        (tz) => tz.timezone === person.timezone
                    )?.name
                }
                ) */}
            </PersonTag>
        ))}
    </PeopleList>

    {/* Date and time inputs */}
    {/* <InputContainer>
        <Input
            type="date"
            value={dateInput}
            onChange={(e) => setDateInput(e.target.value)}
        />
        <Input
            type="time"
            value={timeInput}
            onChange={(e) => setTimeInput(e.target.value)}
        />
        <SelectComponent
            value={selectedTimezone}
            onValueChange={setSelectedTimezone}
            items={[
                { value: 'local', label: 'Local Time' },
                ...availableTimezones.map((tz) => ({
                    value: tz.timezone,
                    label: tz.name
                }))
            ]}
            placeholder="Select Timezone"
        />
    </InputContainer> */}

    {/* Time slider */}
    
    <div style={{ marginBottom: '1rem' }}>
        <label
            htmlFor="base-time-slider"
            style={{ display: 'block', marginBottom: '0.5rem' }}>
            Time Preview
        </label>
        <SliderRoot
            id="base-time-slider"
            value={[baseTime.getHours() * 60 + baseTime.getMinutes()]}
            min={0}
            max={1440}
            step={60} // Change to 1-hour steps for smoother animation
            onValueChange={handleSliderChange}
            disabled={isSliding}>
            <SliderTrack>
                <SliderRange />
            </SliderTrack>
            <SliderThumb />
        </SliderRoot>
        <div style={{ marginTop: '0.5rem' }}>
            {formatTime(baseTime)}
        </div>
    </div>

    {/* Add Timezone dropdown */}
    {/* <div style={{ marginBottom: '1rem' }}>
        <SelectComponent
            value=""
            onValueChange={(value) =>
                handleAddTimezone(
                    availableTimezones.find(
                        (tz) => tz.timezone === value
                    )
                )
            }
            items={availableTimezones.map((tz) => ({
                value: tz.timezone,
                label: tz.name
            }))}
            placeholder="Add Timezone"
        />
    </div> */}

    {/* <FocusInputContainer onKeyDown={handleKeyDown}>
        <FocusInput
            ref={hourInputRef}
            type="number"
            min={is24HourFormat ? '0' : '1'}
            max={is24HourFormat ? '23' : '12'}
            value={focusHourInput}
            onChange={handleFocusHourChange}
            onKeyPress={handleKeyPress}
            placeholder="Hour"
        />
        {!is24HourFormat && (
            <AmPmToggle>
                <AmPmButton
                    ref={amButtonRef}
                    className={focusPeriod === 'AM' ? 'active' : ''}
                    onClick={() => setFocusPeriod('AM')}>
                    AM
                </AmPmButton>
                <AmPmButton
                    ref={pmButtonRef}
                    className={focusPeriod === 'PM' ? 'active' : ''}
                    onClick={() => setFocusPeriod('PM')}>
                    PM
                </AmPmButton>
            </AmPmToggle>
        )}
        <SelectComponent
            ref={timezoneSelectRef}
            value={focusTimezone}
            onValueChange={setFocusTimezone}
            onOpenChange={handleSelectOpenChange}
            items={availableTimezones.map((tz) => ({
                value: tz.timezone,
                label: tz.name
            }))}
            placeholder="Select Timezone"
        />
        <FocusButton ref={focusButtonRef} onClick={handleFocusHour}>
            Focus
        </FocusButton>
    </FocusInputContainer> */}

    {/* <ToggleContainer>
        <ToggleLabel htmlFor="time-format-toggle">
            {is24HourFormat ? '24-hour' : '12-hour'} format
        </ToggleLabel>
        <StyledSwitch
            id="time-format-toggle"
            checked={is24HourFormat}
            onCheckedChange={setIs24HourFormat}>
            <StyledThumb />
        </StyledSwitch>
    </ToggleContainer> */}

    {/* Timezone table */}
    <TableContainer onMouseLeave={handleTableMouseLeave}>
        {(hoverPosition || hoveredColumn) && (
            <AnimatedHoverIndicator
                style={{
                    left: `${
                        hoverPosition
                            ? hoverPosition.left
                            : (hoveredColumn - 1) * (100 / 27)
                    }px`,
                    width: `${
                        hoverPosition ? hoverPosition.width : 100 / 27
                    }px`,
                    height: `100%`,
                    transform: hoverIndicatorTransform
                }}
            />
        )}
        <AnimatedTable ref={tableRef} className={tableAnimation}>
            <tbody>
                <CalRow>
                    <CalCell>Calendar</CalCell>
                    {renderCalendarEvents()}
                </CalRow>
                {visibleTimezones.map((timezone, rowIndex) => {
                    const locationTime = getLocationTimeLive(timezone);
                    const day = getDay(locationTime);
                    const showDay = day !== majorityDay;
                    const rowAnimation = rowAnimations[timezone] || '';

                    return (
                        <React.Fragment key={`${rowIndex}-${timezone}`}>
                            <AnimatedTableRow className={rowAnimation}>
                                <TZCell>
                                    <TZName>
                                        {
                                            availableTimezones.find(
                                                (tz) =>
                                                    tz.timezone ===
                                                    timezone
                                            )?.name
                                        }
                                    </TZName>
                                    <TZTime>
                                        {formatTimeWithBlink(
                                            locationTime
                                        )}
                                        <span
                                            style={{
                                                fontStyle: 'italic',
                                                opacity: 0.95,
                                                fontSize: '12px',
                                                fontFamily:
                                                    'Product Sans Regular'
                                            }}>
                                            {showDay && ` - ${day} `}
                                        </span>
                                    </TZTime>
                                </TZCell>
                                {[...Array(27)].map((_, cellIndex) => {
                                    const hour =
                                        (locationTime.getHours() +
                                            cellIndex -
                                            3 +
                                            24) %
                                        24;
                                    const isHighlighted = isCurrentHour(
                                        hour,
                                        locationTime
                                    );
                                    const isFocused =
                                        focusedColumn === cellIndex + 1;
                                    if (hour === 0 && cellIndex >= 3) {
                                        return (
                                            <DateCell
                                                key={`date-${cellIndex}`}
                                                onMouseEnter={(event) =>
                                                    handleCellHover(
                                                        event,
                                                        rowIndex,
                                                        cellIndex + 1
                                                    )
                                                }
                                                onClick={() =>
                                                    handleCellClick(
                                                        cellIndex + 1
                                                    )
                                                }
                                                style={{
                                                    opacity:
                                                        focusedColumn !==
                                                            null &&
                                                        !isFocused
                                                            ? 0.3
                                                            : 1,
                                                    backgroundColor:
                                                        isFocused
                                                            ? '#4c9aff'
                                                            : undefined,
                                                    color: isFocused
                                                        ? '#ffffff'
                                                        : undefined
                                                }}>
                                                {getFormattedDate(
                                                    locationTime,
                                                    cellIndex
                                                )}
                                            </DateCell>
                                        );
                                    }
                                    return (
                                        <AnimatedTableCell
                                            className={cellAnimation}
                                            key={cellIndex}
                                            style={{
                                                backgroundColor:
                                                    isHighlighted
                                                        ? 'rgba(76, 154, 255, 0.2)'
                                                        : isFocused
                                                        ? 'rgba(76, 154, 255, 0.2)'
                                                        : 'transparent',
                                                color:
                                                    isHighlighted ||
                                                    isFocused
                                                        ? '#ffffff'
                                                        : 'inherit',
                                                opacity:
                                                    focusedColumn !==
                                                        null &&
                                                    !isFocused
                                                        ? 0.3
                                                        : 1,
                                                cursor: 'pointer'
                                            }}
                                            onMouseEnter={(event) =>
                                                handleCellHover(
                                                    event,
                                                    rowIndex,
                                                    cellIndex + 1
                                                )
                                            }
                                            onClick={() =>
                                                handleCellClick(
                                                    cellIndex + 1
                                                )
                                            }>
                                            {formatHour(hour)}
                                        </AnimatedTableCell>
                                    );
                                })}
                            </AnimatedTableRow>
                            <AnimatedProfileRow
                                className={rowAnimation}>
                                <ProfileCell>
                                    {renderProfilePictures(timezone)}
                                </ProfileCell>
                                {[...Array(27)].map((_, cellIndex) => (
                                    <ProfileCell key={cellIndex} />
                                ))}
                            </AnimatedProfileRow>
                        </React.Fragment>
                    );
                })}
            </tbody>
        </AnimatedTable>
    </TableContainer>
</Container>
);
};

export default App;
