import { keyframes, styled } from '@stitches/react';
import * as Slider from '@radix-ui/react-slider';
import * as Select from '@radix-ui/react-select';
import * as Switch from '@radix-ui/react-switch';

// Styled components (reused from previous example)
const Container = styled('div', {
    padding: '1rem',
    height: '100vh',
    backgroundColor: '#1a1a1a',
    color: '#ffffff'
});

const Title = styled('h1', {
    fontSize: '2rem',
    fontWeight: 'bold',
    marginRight: '8px'
    // marginBottom: '1rem'
});

const SliderRoot = styled(Slider.Root, {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    userSelect: 'none',
    touchAction: 'none',
    width: '100%',
    height: 20
});

const SliderTrack = styled(Slider.Track, {
    backgroundColor: '#3a3a3a',
    position: 'relative',
    flexGrow: 1,
    borderRadius: '9999px',
    height: 3
});

const SliderRange = styled(Slider.Range, {
    position: 'absolute',
    backgroundColor: '#4c9aff',
    borderRadius: '9999px',
    height: '100%'
});

const SliderThumb = styled(Slider.Thumb, {
    display: 'block',
    width: 20,
    height: 20,
    backgroundColor: 'white',
    borderRadius: '10px',
    '&:hover': { backgroundColor: '#f6f6f6' },
    '&:focus': { boxShadow: '0 0 0 5px rgba(76, 154, 255, 0.5)' }
});

const SelectTrigger = styled(Select.SelectTrigger, {
    all: 'unset',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: 4,
    padding: '0 15px',
    fontSize: 13,
    lineHeight: 1,
    height: 35,
    gap: 5,
    backgroundColor: '#2a2a2a',
    color: '#ffffff',
    boxShadow: `0 2px 10px #0000000d`,
    '&:hover': { backgroundColor: '#3a3a3a' },
    '&:focus': { boxShadow: `0 0 0 2px black` },
    '&[data-placeholder]': { color: '#888888' }
});

const SelectIcon = styled(Select.SelectIcon, {
    color: '#888888'
});

const SelectContent = styled(Select.Content, {
    overflow: 'hidden',
    backgroundColor: '#2a2a2a',
    borderRadius: 6,
    boxShadow:
        '0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2)'
});

const SelectViewport = styled(Select.Viewport, {
    padding: 5
});

const SelectItem = styled(Select.Item, {
    all: 'unset',
    fontSize: 13,
    lineHeight: 1,
    color: '#ffffff',
    borderRadius: 3,
    display: 'flex',
    alignItems: 'center',
    height: 25,
    padding: '0 35px 0 25px',
    position: 'relative',
    userSelect: 'none',

    '&[data-highlighted]': {
        outline: 'none',
        backgroundColor: '#4c9aff'
    }
});

const SelectItemIndicator = styled(Select.ItemIndicator, {
    position: 'absolute',
    left: 0,
    width: 25,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center'
});

const SelectLabel = styled(Select.Label, {
    padding: '0 25px',
    fontSize: 12,
    lineHeight: '25px',
    color: '#888888'
});

const SelectSeparator = styled(Select.Separator, {
    height: 1,
    backgroundColor: '#444444',
    margin: 5
});

const SelectScrollUpButton = styled(Select.ScrollUpButton, {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 25,
    backgroundColor: '#2a2a2a',
    color: '#888888',
    cursor: 'default'
});

const SelectScrollDownButton = styled(Select.ScrollDownButton, {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 25,
    backgroundColor: '#2a2a2a',
    color: '#888888',
    cursor: 'default'
});

const TableContainer = styled('div', {
    position: 'relative',
    maxWidth: '1800px'
});

const HoverIndicator = styled('div', {
    position: 'absolute',
    top: 0,
    bottom: 0,
    borderLeft: '2px solid #4c9aff',
    borderRight: '2px solid #4c9aff',
    borderBottom: '2px solid #4c9aff',
    borderTop: '2px solid #4c9aff',
    pointerEvents: 'none',
    borderRadius: '8px',
    transition: 'all 0.1s ease-out',
    zIndex: 6
});

const ToggleContainer = styled('div', {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1rem'
});

const ToggleLabel = styled('label', {
    marginRight: '0.5rem',
    color: '#ffffff'
});

const StyledSwitch = styled(Switch.Root, {
    width: 42,
    height: 25,
    backgroundColor: '#2a2a2a',
    borderRadius: '9999px',
    position: 'relative',
    WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)',
    '&[data-state="checked"]': { backgroundColor: '#4c9aff' }
});

const StyledThumb = styled(Switch.Thumb, {
    display: 'block',
    width: 21,
    height: 21,
    backgroundColor: 'white',
    borderRadius: '9999px',
    transition: 'transform 100ms',
    transform: 'translateX(2px)',
    willChange: 'transform',
    '&[data-state="checked"]': { transform: 'translateX(19px)' }
});

const Table = styled('table', {
    width: '100%',
    borderCollapse: 'separate',
    borderSpacing: '0 10px',
    marginTop: '5px',
    position: 'relative'
});

const TableRow = styled('tr', {
    backgroundColor: '#2a2a2a'
});

const CalRow = styled('tr', {
    // backgroundColor: '#2a2a2a'
});

const TableCell = styled('td', {
    padding: '5px',
    minWidth: '55px',
    height: '55px',
    textAlign: 'center',
    border: 'none',
    position: 'relative',
    transition: 'opacity 0.2s ease-out',
    '&:first-child': {
        fontWeight: 'bold',
        backgroundColor: '#3a3a3a',
        borderTopLeftRadius: '8px',
        borderBottomLeftRadius: '8px',
        width: 'auto',
        padding: '10px',
        zIndex: 2
    },
    '&:last-child': {
        borderTopRightRadius: '8px',
        borderBottomRightRadius: '8px'
    }
});

const CalCell = styled('td', {
    // padding: '5px',
    // minWidth: '55px',
    maxWidth: '65px',
    height: '20px',
    textAlign: 'center',
    border: 'none',
    position: 'relative',
    transition: 'opacity 0.2s ease-out',
    '&:first-child': {
        fontWeight: 'bold',
        // backgroundColor: '#3a3a3a',
        borderTopLeftRadius: '8px',
        borderBottomLeftRadius: '8px',
        width: 'auto',
        fontSize: '12px',
        padding: '10px'
        // zIndex: 2
    },
    '&:last-child': {
        borderTopRightRadius: '8px',
        borderBottomRightRadius: '8px'
    }
});

const TZCell = styled('td', {
    padding: '5px',
    minWidth: '95px',
    height: '55px',
    textAlign: 'center',
    border: 'none',
    position: 'relative',
    transition: 'opacity 0.2s ease-out',
    '&:first-child': {
        backgroundColor: '#3a3a3a',
        borderTopLeftRadius: '8px',
        borderBottomLeftRadius: '8px',
        width: 'auto',
        padding: '10px',
        verticalAlign: 'top',
        zIndex: 2
    },
    '&:last-child': {
        borderTopRightRadius: '8px',
        borderBottomRightRadius: '8px'
    }
});

const TZName = styled('div', {
    fontSize: '13px',
    color: '#ffffff',
    opacity: 0.85,
    marginBottom: '5px'
});

const TZTime = styled('div', {
    fontSize: '14px',
    fontFamily: 'Product Sans Bold',
    color: '#ffffff'
});

const HourCell = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
});

const Hour = styled('span', {
    fontSize: '1rem'
});

const AmPm = styled('span', {
    fontSize: '0.7rem',
    marginTop: '2px'
});

const RemoveButton = styled('button', {
    marginLeft: '0.5rem',
    padding: '0.25rem 0.5rem',
    backgroundColor: '#4c9aff',
    color: '#ffffff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    '&:hover': {
        backgroundColor: '#3a87ff'
    }
});

const DateCell = styled(TableCell, {
    fontWeight: 'bold',
    fontSize: '12px',
    backgroundColor: '#3a3a3a',
    boxShadow: 'inset 2px 0 0 #4c9aff, inset -2px 0 0 #4c9aff'
});

const EventCell = styled(TableCell, {
    backgroundColor: '#4c9aff',
    height: '20px',
    color: '#ffffff',
    fontWeight: 'bold',
    fontSize: '12px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
});

const EventCellEmpty = styled(TableCell, {
    height: '20px',
    color: '#ffffff',
    fontWeight: 'bold',
    fontSize: '12px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
});

const InputContainer = styled('div', {
    display: 'flex',
    gap: '1rem',
    marginBottom: '1rem'
});

const Input = styled('input', {
    padding: '0.5rem',
    fontSize: '1rem',
    backgroundColor: '#2a2a2a',
    color: '#ffffff',
    border: '1px solid #3a3a3a',
    borderRadius: '4px',
    '&:focus': {
        outline: 'none',
        borderColor: '#4c9aff'
    }
});

const PeopleList = styled('div', {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: '4px',
    marginBottom: '1rem'
});

const PersonTag = styled('div', {
    display: 'flex',
    alignItems: 'center',
    margin: '0px',
    padding: '4px 8px',
    borderRadius: '4px',
    transition: 'linear 0.2s ease-out',
    fontSize: '14px'
});

const PersonCheckbox = styled('input', {
    margin: 0
});

const FocusInputContainer = styled('div', {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1rem',
    gap: '10px'
});

const FocusInput = styled('input', {
    padding: '5px',
    fontSize: '1rem',
    backgroundColor: '#2a2a2a',
    color: '#ffffff',
    border: '1px solid #3a3a3a',
    borderRadius: '4px',
    width: '50px'
});

const FocusButton = styled('button', {
    padding: '5px 10px',
    fontSize: '1rem',
    backgroundColor: '#4c9aff',
    color: '#ffffff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    '&:hover': {
        backgroundColor: '#3a87ff'
    }
});

const AmPmToggle = styled('div', {
    display: 'flex',
    border: '1px solid #3a3a3a',
    borderRadius: '4px',
    overflow: 'hidden'
});

const AmPmButton = styled('button', {
    padding: '5px 10px',
    fontSize: '0.8rem',
    border: 'none',
    cursor: 'pointer',
    '&.active': {
        backgroundColor: '#4c9aff',
        color: '#ffffff'
    },
    '&:not(.active)': {
        backgroundColor: '#2a2a2a',
        color: '#ffffff'
    }
});

const ProfilePic = styled('img', {
    width: '28px',
    height: '28px',
    borderRadius: '50%',
    userSelect: 'none'
});

const PersonName = styled('span', {
    marginRight: '8px'
});

const ProfileRow = styled('tr', {
    height: '10px',
    marginTop: '-22px',
    // marginLeft: '6px',
    zIndex: 4,
    borderSpacing: '0',
    position: 'absolute'
});

const ProfileCell = styled('td', {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    // padding: '5px',
    justifyContent: 'center',
    width: '115px',
    height: '10px'
    // marginTop: '-50px!important',
    // borderSpacing: '0'
});

const TimeDisplay = styled('span', {
    display: 'inline-flex',
    alignItems: 'center'
});

const BlinkingColon = styled('span', {
    variants: {
        visible: {
            true: {
                opacity: 1
            },
            false: {
                opacity: 0
            }
        }
    }
});

// Add these new styled components for the stacked events
const EventContainer = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    maxWidth: '65px',
    backgroundColor: 'transparent'
});

const EventItem = styled('div', {
    flex: 1,
    fontSize: '10px',
    fontWeight: 'bold',
    // padding: '2px 4px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    transition: 'opacity 0.3s ease',
    variants: {
        greyed: {
            true: {
                opacity: 0.5
            }
        }
    }
});

const EventCellCal = styled(TableCell, {
    height: '20px',
    padding: '0',
    backgroundColor: 'transparent',
    '&:first-child': {
        // backgroundColor: '#3a3a3a',
        borderTopLeftRadius: '8px',
        borderBottomLeftRadius: '8px'
        // width: 'auto',
        // padding: '10px',
        // verticalAlign: 'top',
        // zIndex: 2
    },
    '&:last-child': {
        borderTopRightRadius: '8px',
        borderBottomRightRadius: '8px'
    }
});

const slideLeft = keyframes({
    '0%': { transform: 'translateX(0)', opacity: 1 },
    '100%': { transform: 'translateX(-100%)', opacity: 0 }
});

const slideRight = keyframes({
    '0%': { transform: 'translateX(100%)', opacity: 0 },
    '100%': { transform: 'translateX(0)', opacity: 1 }
});

const fadeIn = keyframes({
    '0%': { opacity: 0, transform: 'translateY(-10px)' },
    '100%': { opacity: 1, transform: 'translateY(0)' }
});

const fadeOut = keyframes({
    '0%': { opacity: 1, transform: 'translateY(0)' },
    '100%': { opacity: 0, transform: 'translateY(-10px)' }
});

const AnimatedTable = styled(Table, {
    position: 'relative',
    '&::before, &::after': {
        content: '""',
        position: 'absolute',
        top: 0,
        bottom: 0,
        width: '100%',
        pointerEvents: 'none'
    },
    '&.slide-left::before': {
        animation: `${slideLeft} 0.2s cubic-bezier(0.25, 0.1, 0.25, 1)`
    },
    '&.slide-right::after': {
        animation: `${slideRight} 0.2s cubic-bezier(0.25, 0.1, 0.25, 1)`
    }
});

const AnimatedTableCell = styled(TableCell, {
    transition: 'opacity 0.5s ease-out',
    '&.slide-left': {
        animation: `${slideLeft} 0.2s cubic-bezier(0.25, 0.1, 0.25, 1) forwards`
    },
    '&.slide-right': {
        animation: `${slideRight} 0.2s cubic-bezier(0.25, 0.1, 0.25, 1) forwards`
    }
});

const AnimatedTableRow = styled(TableRow, {
    '&.fade-in': {
        animation: `${fadeIn} 0.2s ease-out forwards`
    },
    '&.fade-out': {
        animation: `${fadeOut} 0.2s ease-out forwards`
    }
});

const AnimatedProfileRow = styled(ProfileRow, {
    '&.fade-in': {
        animation: `${fadeIn} 0.2s ease-out forwards`
    },
    '&.fade-out': {
        animation: `${fadeOut} 0.2s ease-out forwards`
    }
});

const AnimatedHoverIndicator = styled(HoverIndicator, {
    transition:
        'transform 0.2s cubic-bezier(0.25, 0.1, 0.25, 1), left 0.2s cubic-bezier(0.25, 0.1, 0.25, 1)'
});

const TestButton = styled('button', {
    padding: '10px 20px',
    fontSize: '16px',
    backgroundColor: '#4c9aff',
    color: '#ffffff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    marginBottom: '20px',
    '&:hover': {
        backgroundColor: '#3a87ff'
    }
});

// Export styled components
export {
    Container,
    Title,
    SliderRoot,
    SliderTrack,
    SliderRange,
    SliderThumb,
    SelectTrigger,
    SelectIcon,
    SelectContent,
    SelectViewport,
    SelectItem,
    SelectItemIndicator,
    SelectLabel,
    SelectSeparator,
    SelectScrollUpButton,
    SelectScrollDownButton,
    TableContainer,
    HoverIndicator,
    ToggleContainer,
    ToggleLabel,
    StyledSwitch,
    StyledThumb,
    Table,
    TableRow,
    TableCell,
    HourCell,
    Hour,
    AmPm,
    RemoveButton,
    DateCell,
    EventCell,
    InputContainer,
    Input,
    PeopleList,
    PersonTag,
    PersonCheckbox,
    FocusInputContainer,
    FocusInput,
    FocusButton,
    AmPmToggle,
    AmPmButton,
    ProfilePic,
    PersonName,
    ProfileRow,
    ProfileCell,
    TZCell,
    TZName,
    TZTime,
    TimeDisplay,
    BlinkingColon,
    CalRow,
    CalCell,
    EventCellEmpty,
    EventContainer,
    EventItem,
    EventCellCal,
    AnimatedTable,
    AnimatedHoverIndicator,
    TestButton,
    AnimatedTableCell,
    AnimatedTableRow,
    AnimatedProfileRow
};
